.moon-light.c-app,
.moon-light .c-app,
.moon-light .c-sidebar-minimizer {
  background-color: #212631;
  color: #FFFFFF;
}


.moon-light .card,
.moon-light .c-footer,
.moon-light .card-header,
.moon-light .choose-printer-popup,
.moon-light .c-sidebar .c-sidebar-brand,
.moon-light .c-sidebar,
.moon-light .c-sidebar-minimizer.c-d-md-down-none,
.moon-light .c-header {
  background-color: #2d3443 !important;
  color: #FFFFFF;
}

.moon-light .c-sidebar .c-sidebar-minimizer:hover,
.moon-light .c-sidebar-minimizer.c-d-md-down-none {
  box-shadow: -10px 0px 10px -4px #989898;
}

.moon-light .c-sidebar .c-sidebar-nav-link,
.moon-light .c-sidebar .c-sidebar-nav-dropdown-toggle {
  background-color: #2d3443;
  color: #a7afbc;
  border-right: 1px dotted
}

.moon-light .sales_by_pl h5,
.moon-light .table {
  color: #98a3ad;
}

.moon-light .table tr:hover {
  color: #a7bbcd;
}

.moon-light .ReactTable .rt-tbody .rt-tr-group {
  background-color: #222936;
  color: #98a3ad;
}

.moon-light .rt-tr-group:nth-child(2n),
.moon-light .table-strip tbody tr:nth-of-type(odd),
.moon-light .order-history-table tbody tr:nth-of-type(odd) {
  background-color: #3c4351 !important;
  color: #98a3ad;
}

.moon-light .modal-content {
  background: #465162;
}

.moon-light input,
.moon-light .form-control {
  background: #c0c6cf;
}

.moon-light .ReactTable .rt-thead.-filters input,
.moon-light .ReactTable .rt-thead.-filters select,
.moon-light .ReactTable input,
.moon-light .page-link,
.moon-light .orders-select .css-2b097c-container .css-yk16xz-control,
.moon-light .orders-select .css-2b097c-container .css-1pahdxg-control {
  background: #535b68;
}

.moon-light .ReactTable input {
  color: aliceblue;
}

.moon-light .table th,
.moon-light .table thead th,
.moon-light .ReactTable .rt-thead.-headerGroups,
.moon-light .rt-th.rt-resizable-header {
  background: #0d1420;
}

.moon-light .general-card-body {
  background: transparent;
}

.moon-light .upload-file-status-button {
  background: transparent;
  color: #98a3ad;
  margin-left: 8px;
}


.moon-light .scrollable::-webkit-scrollbar-thumb,
.moon-light .table-responsive::-webkit-scrollbar-thumb,
.moon-light .ReactTable .rt-table::-webkit-scrollbar-thumb {
  background-color: #475674;
  outline: 1px solid slategrey;
}

body::-webkit-scrollbar,
.moon-light .scrollable::-webkit-scrollbar,
.moon-light .table-responsive::-webkit-scrollbar,
.moon-light .ReactTable .rt-table::-webkit-scrollbar {
  width: .5em;
  height: .5em;
}

.moon-light .scrollable::-webkit-scrollbar-track,
.moon-light .table-responsive::-webkit-scrollbar-track,
.moon-light .ReactTable .rt-table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.moon-light .chat-box .popup-box {
  background-color: #222e47;
}

.moon-light .msg-li {
  background-color: #455473;
}

.moon-light .react-tags__search-input::placeholder {
  color: #fff;
}
