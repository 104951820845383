body {
    font-family: 'Poppins', sans-serif;
    color: #212529;
    font-weight: 400;
    font-size: 14px;
}

a {
    color: #22b7c0;
}

input:focus {
    box-shadow: none !important;
}

input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox  */
input[type=number] {
    -moz-appearance: textfield;
}

/* Sidebar */
.c-sidebar .c-sidebar-brand {
    background: #2b2b2b;
    border-right: 1px dotted #d8dbe0;
}

.c-sidebar {
    background: #2b2b2b !important;
}

.c-header {
    background: #22b7c0;
    border-bottom: 1px solid #d8dbe0;
}

.c-header .c-header-nav .c-header-nav-link,
.c-header .c-header-nav .c-header-nav-btn {
    color: #ffffff !important;
}

.c-sidebar .c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar .c-sidebar-nav-link:hover {
    color: #22b7c0 !important;
    background: #321fdb00;
}

/* .c-sidebar-brand>img {
    width: 65px;
} */
.c-sidebar-nav li>a {
    color: #8d97ad !important;
    background: transparent;
    border-bottom: 1px dotted #4d4d4d;
    box-shadow: 0px 3px 10px -4px #080808;
    font-size: 14px;
}

.c-sidebar-nav-dropdown-items li>a {
    box-shadow: none !important;
    border: none !important;
}

.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle,
.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link {
    color: #22b7c0;
}

.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link.c-active .c-sidebar-nav-icon {
    color: #22b7c0;
}

/* .c-header {
min-height: 66px;
} */
.c-avatar-img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

.c-sidebar .c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link:hover .c-sidebar-nav-icon {
    color: #22b7c0;
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover>.c-sidebar-nav-link,
.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover>.c-sidebar-nav-dropdown-toggle {
    background: #2b2b2b;
}

.c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
    color: #787f91;
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover>.c-sidebar-nav-link .c-sidebar-nav-icon,
.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover>.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
    color: #22b7c0;
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav>.c-sidebar-nav-dropdown:hover {
    background: #2b2b2b;
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav>.c-sidebar-nav-dropdown>.c-sidebar-nav-dropdown-items {
    background: #2b2b2b;
}

.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle,
.c-sidebar .c-sidebar-nav-link.c-active {
    color: #22b7c0 !important;
    background: hsl(0deg 27% 36% / 5%);
}

.manage-permission {
    box-shadow: 0px 0px 10px -4px #989898;
    border: none;
    border-color: transparent;
}

/* .c-sidebar-minimized ul li a:hover > span {
    display: inline !important;
}
.c-sidebar-minimized ul li a > span {
    display: none;
}
.c-sidebar-minimized ul li a {
    padding: 15px 15px 15px 25px;
} */
/* .c-sidebar-minimized .c-sidebar-brand > img {
    width: 52px;
} */


/* Login */
.space-bw {
    justify-content: space-between
}

.custom-control-input:checked~.custom-control-label::before {
    border-color: #22b7c0 !important;
    background-color: #22b7c0 !important;
}

.custom-control-input-after:checked~.custom-control-label-after::after {
    border-color: #22b7c0 !important;
    background-color: #22b7c0 !important;
    width: 25px;
}

.custom-control-input-after {
    width: 25px;
}

label.custom-control-label:focus {
    outline: none !important;
}

label.custom-control-label-after:focus {
    outline: none !important;
}

.page-link {
    color: #22b7c0 !important;
}

.page-link:hover {
    color: #f95c2e !important;
}

.page-item.active .page-link {
    color: #fff !important;
    background-color: #22b7c0;
    border-color: #22b7c0;
}

.justify-content-start {
    justify-content: flex-end !important;
}

/* React select */
.css-g1d714-ValueContainer {
    width: 300px !important;
}

.css-1hwfws3 {
    width: 300px !important;
}

/* Toast */
.Toastify__toast--success {
    background: #18DAE6 !important;
}

/* Table */
.CDataTable_arrow-position__2xCKr {
    color: #ffffff !important;
}

.CDataTable_transparent__3viwN {
    opacity: 1 !important;
}

.table th,
.table thead th {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
    background: #2b2b2b;
    color: #fff;
    padding: 6px;
    overflow: visible !important;
}

.table-badge {
    height: 35px;
    width: 39px;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    color: #ffffff;
    cursor: pointer;
}

.table-badge:last-child {
    margin-right: 0px;
}

/* button */
.btn-primary {
    background-color: #22b7c0;
    border-color: #22b7c0;
}

.btn-warning {
    color: #fff;
    background-color: #fec107 !important;
    border-color: #fec107 !important;
}

.btn-warning:hover {
    color: #fff;
    background-color: #dea701 !important;
    border-color: #d19e01 !important;
}

.pull-right {
    float: right;
}

button:hover,
button:focus,
button:active,
a:hover,
a:focus,
a:active {
    box-shadow: none !important;
    outline: none;
}

a:hover,
a:focus,
a:active {
    color: #f95c2e;
}

/* Badge */
.badge-primary:hover,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
    background-color: #219198 !important;
    border: 1px solid #219198 !important;
}

.badge-warning:hover {
    background-color: #dea701;
}

.badge-warning {
    color: #4f5d73;
    background-color: #fec107;
}

.badge-danger:hover {
    background-color: #de4a58;
}

.badge-danger {
    color: #fff;
    background-color: #e46a76;
}

.badge-success {
    color: #fff;
    background-color: #00c292;
}

.badge-success:hover {
    color: #fff;
    background-color: #009c75;
}

.badge-info:hover {
    background-color: #038fcd;
}

.badge-primary {
    background-color: #22b7c0;
    border-color: #22b7c0;
}

.dropdown-item.active,
.dropdown-item:active {
    text-decoration: none;
    color: #fff;
    background-color: #22b7c0;
}

.user-table thead th:nth-child(6) {
    width: 200px;
}

.user-table tbody td:nth-child(4) {
    text-transform: capitalize;
}

span.avtar_text {
    background-color: #ffffff;
    align-items: center;
    justify-content: center;
    display: flex;
    text-transform: uppercase;
    margin: 0 auto;
    color: #22b7c0;
    font-size: 24px;
    width: 100%;
    height: auto;
    border-radius: 50em;
}

.table-sm th {
    background: white;
}

.user-table {
    white-space: nowrap;
    /* table-layout: fixed; */
}

.user-table tr td {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.dt-button {
    border-radius: 0.25rem;
    background: #22b7c0;
    text-transform: uppercase;
    font-size: 13px;
    color: #fff;
    margin-right: 8px;
    padding: 6px 30px;
    border: 1px solid #20b5bd;
}

/* Order details page */
.nowrap {
    white-space: nowrap;
}

.border-none {
    border: none !important;
}

.container-fluid {
    padding: 0px 25px;
}

.order-details-page header.card-header,
.return-id-section header.card-header {
    padding: 7px 10px;
    font-size: 18px;
    border-radius: 0;
    font-weight: 300;
}

.order-details-page input {
    height: auto !important;
}

.order-details td,
.order-details th {
    border: 1px solid #dee2e6;
    font-size: 13px !important;
}

.general-comment-card .card-body .card-header {
    font-size: 12px !important;
}

.general-table td,
.general-table th {
    border: none;
}

.general-card-body {
    padding: 0 !important;
    background: #f8f9fa;
}

.order-history-table tbody tr:nth-of-type(odd) {
    background: #f8f9fa;
}

.form-control:focus {
    /* border: none; */
    border-color: #22b7c0 !important;

    /* box-shadow: 0px 0px 4px 0px #0662bc !important; */
    /* -webkit-box-shadow: 0px 0px 4px 0px #0662bc !important; */
    box-shadow: none !important;
}

.order-card {
    padding-right: 5px;
    padding-left: 5px;
}

.order-details tbody {
    width: 100%;
    display: table;
}

/* order-details table css*/
.order-details .heading {
    font-weight: 500;
    width: 150px;
    max-width: 150px;
}

.order-details td {
    padding: 11px 6px !important;
}

.order-details .td-btn {
    width: 105px;
    background-color: #22b7c0;
    color: white;
}

.theme-bg-color {
    background-color: #22b7c0 !important;
}

.general-cmmt-btn {
    background: #22b7c0;
    color: #fff;
    padding: 1px 10px;
    border-radius: 10px;
}

.create-label {
    background-color: #080808;
    color: white;
    padding: .25rem .5rem;
    font-size: 10px;
}

.create-label:hover {
    color: white !important;
    background-color: #009c75;
    border-color: #008f6c
}

/* Error Message CSS */
.error-message {
    padding: 0 !important;
}

.custom-height {
    margin-bottom: 0;
    height: 64px;
}

/* custom button disable */
.custom-btn {
    background-color: #22b7c0;
    border-color: #22b7c0;
}

.custom-btn:hover {
    color: #fff !important;
    background-color: #2b2b2b !important;
    border-color: #2b2b2b;
}

.custom-btn.disabled,
.custom-btn:disabled {
    color: #212529;
    background-color: #22b7c0;
    border-color: #22b7c0;
}

/* tagging  */
.public-DraftEditorPlaceholder-root {
    position: absolute !important;
}

.draftJsMentionPlugin__mention__29BEd,
.draftJsMentionPlugin__mention__29BEd:visited {
    color: #ffffff !important;
    cursor: pointer;
    display: inline-block;
    background: #22b7c0 !important;
    padding: 1px 10px !important;
    border-radius: 10px !important;
    text-decoration: none;
    margin-bottom: 10px !important;
}

.draftJsMentionPlugin__mentionSuggestions__2DWjA {
    background: #22b7c0 !important;
    /* color: #ffffff !important; */
}

.btn-primary:hover {
    background-color: #2b2b2b !important;
    border-color: #2b2b2b !important;
}

input[type=checkbox] {
    -ms-transform: scale(1.1);
    /* IE */
    -moz-transform: scale(1.1);
    /* FF */
    -webkit-transform: scale(1.1);
    /* Safari and Chrome */
    -o-transform: scale(1.1);
    /* Opera */
    transform: scale(1.1);
    padding: 10px;
}

.select-box-div {
    display: inline-block;
}

.select-box-div .css-11unzgr>div {
    display: flex !important;
    align-items: center;
    padding: 10px 8px !important;
    color: #505356 !important;
    font-size: 15px !important;
    background-color: transparent;
    font-weight: normal !important;
}

.select-box-div .css-11unzgr {
    padding: 0px 0 10px;
    box-shadow: 0px 0px 15px -2px #000;
}

.select-box-div .css-11unzgr>div>input {
    height: auto !important;
    margin-right: 10px !important;
}

.css-1r4vtzz {
    background-color: #20b5bd !important;
    border-radius: 0.25rem !important;
    height: 32px !important;
}

.css-1v99tuv {
    color: #ffffff !important;
}

.css-48ayfv {
    background-color: #20b5bd !important;
    border-radius: 0.25rem !important;
    height: 32px !important;
}

.css-1pcexqc-container {
    width: 250px;
    overflow-x: hidden;
}

/* .css-1jllj6i-control {
    display: none !important;
} */
.css-1vr111p-option {
    border-bottom: 1px solid #e7e7e7;
}

.css-1qprcsu-option {
    border-bottom: 1px solid #e7e7e7;
}

.css-1r4vtzz:hover {
    background-color: #2b2b2b !important;
    border-color: #2b2b2b;
}

.select-box-div .css-1gpjby2 {
    color: white;
}

.product-input {
    width: 100%;
    border: 0 !important;
    padding: 0 !important;
}

.scrollable {
    overflow: scroll;
}

.add-product-body .inner-td {
    padding: 0 !important;
}

.add-product-body .table-bordered th,
.add-product-body .table-bordered td {
    padding: 7px 10px;
    border: 1px solid #dee2e6;
}

.add-product-body .product-input {
    height: auto !important;
}

td.bg-td-product {
    background-color: #8b8b8b !important;
}

td.bg-td-product input {
    background-color: transparent !important;
}

.bg-td-product .text-white input {
    color: #ffffff !important;
}

.add-product-body .table-bordered td .form-select select {
    width: 100%;
    height: 34px;
    margin: 0;
    border: 1px solid #13b1ba;
    color: #fff;
    font-size: 14px;
    background: #22b7c0;
    background-size: 20px;
    background-position: right 10px center;
    font-family: 'Arial';
    padding-left: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.add-product-body .table-bordered td .form-select select:focus {
    border: none !important;
    outline: none !important;
    outline-offset: 0 !important;
}

.form-control[readonly] {
    background-color: #ffffff;
    opacity: 1;
}

.product-input-select div {
    padding: 0;
    border: none;
    min-height: auto !important;
    margin: 0px;
}

.product-input-select:focus {
    border: none;
}

.css-1pahdxg-control:focus,
.css-1pahdxg-control:hover {
    border: none !important;
}

.product-input-select .css-1pahdxg-control {
    box-shadow: none !important;
    border: none !important;
}

.css-1uccc91-singleValue {
    position: relative !important;
    transform: inherit !important;
    top: auto !important;
    color: #212529;
}

.css-1fhf3k1-control {
    background-color: #ffffff !important;
}

.nowrap {
    white-space: nowrap;
}

.selected_platform_td {
    font-weight: bold;
    color: #22b7c0;
}

.icheck-list {
    list-style: none;
    padding-left: 0;
}

.css-1v99tuv {
    visibility: hidden;
    position: relative;
    width: 32px;
}

.css-1v99tuv:after {
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    content: "Hide";
    width: 32px;
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #03a9f3 !important;
    border-color: #03a9f3 !important;
}

.btn-success {
    color: #fff;
    background-color: #00c292 !important;
    border-color: #00c292 !important;
}

.btn-success:hover {
    background-color: #009c75 !important;
    border-color: #009c75 !important;
}

/* dashboard css */
.dashboard-card .card-title {
    position: relative;
    font-weight: 500;
    text-align: left;
}

.text-center {
    text-align: center !important;
}

.graph-data {
    list-style: none;
    padding: 0;
    margin: 0;
}

.graph-data li {
    list-style: none;
    display: inline-block;
    color: #fff;
    padding: 5px 15px;
    width: auto;
    background: #2b2b2b !important;
    text-align: center;
    font-size: 16px;
    min-width: 120px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.graph-data li:last-child {
    margin: 0
}

.graph-data li span {
    display: block;
    font-size: 14px;
}

.new-form-controls {
    display: inline-block;
    float: right;
    width: auto;
    margin-left: 7px;
}

.ticket-card .card-body {
    padding: 10px;
}

.ticket-card .card-body p.text-muted {
    color: #6c757d !important;
    font-weight: 600;
    font-size: 16px;
    margin: 0;
}

/* .ticket-card {
    box-shadow: 0 0 10px -4px #989898;
    border: none;
    border-radius: 0 !important;
} */

.sales_by_pl {
    padding: 5px 0 5px 0;
}

.progress-bars-groups img {
    width: 38px;
    margin-right: 4px;
}

img {
    vertical-align: middle;
    border-style: none;
}

.pull-right {
    float: right;
}

.platform-title {
    font-size: 18px !important;
    margin: 0;
}

.sales_by_pl .progress {
    height: 6px !important;
}

.sales_by_pl h5 {
    font-size: 13px;
    font-weight: 500;
    color: #585858;
}

.amazon-progress-bar {
    background-color: rgb(244, 98, 35, 0.6) !important;
    border-radius: 10px;
}

/* --------end----- */

.css-yk16xz-control {
    border: 1px solid #d8dbe0;
}

.css-1wa3eu0-placeholder {
    color: #768192;
}

.form-control {
    color: #212529;
}

.cursor-pointer {
    cursor: pointer;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.upload-btn-wrapper .btn {
    border: none;
    color: #22b7c0;
    background-color: #eaeaea;
    padding: 8px 20px;
    border-radius: .25rem;
    font-size: 16px;
    width: 100%;
    font-weight: bold;
    cursor: pointer;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    height: 44px !important;
    cursor: pointer;
}

.align-center {
    align-items: center;
}

.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    margin-left: -100px;
    margin-top: -26px;
    text-align: center;
    padding: 1em 0;
    border: 1px solid #1514144d !important;
}

.box-title {
    background: #2a2a2a;
    margin: 0 1px;
    padding: 8px 0;
    color: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.export-download {
    background-color: #ffffff;
    height: 35px;
    width: 35px;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
}

.toggle {
    position: relative;
    overflow: hidden;
}

.toggle {
    height: 0 !important;
    width: 0 !important;
}

.toggle.ios,
.toggle-on.ios,
.toggle-off.ios {
    border-radius: 20px;
}

.toggle.btn {
    min-width: 35px !important;
    min-height: 34px;
}

.toggle.btn-sm {
    min-width: 50px;
    min-height: 30px;
}

.toggle-group {
    position: absolute;
    width: 200%;
    top: 0;
    bottom: 0;
    left: 0;
    transition: left .35s;
    -webkit-transition: left .35s;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.toggle.off .toggle-group {
    left: -100%;
}

.toggle-on {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 50%;
    margin: 0;
    border: 0;
    border-radius: 0;
}

.toggle-off,
.toggle-on {
    text-align: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.toggle-off {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 0;
    margin: 0;
    border: 0;
    border-radius: 0;
}

.toggle-handle {
    position: relative;
    margin: 0 auto;
    padding-top: 0;
    padding-bottom: 0;
    height: 100%;
    width: 0;
    border-width: 0 1px;
}

.toggle.ios .toggle-handle {
    border-radius: 20px;
}

.toggle.ios .toggle-handle {
    width: 68px !important;
}

.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.76563rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.orders-select {
    align-items: center;
    display: inline-flex;
    justify-content: flex-end;
}

.orders-select .css-2b097c-container {
    width: 150px;
}

.orders-select .css-2b097c-container .css-yk16xz-control {
    min-height: 35px !important;
    margin: 0;
    height: 35px;
}

.c-header .c-header-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.c-header .c-header-toggler-icon:hover {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(255 255 255 / 62%)' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");

}

.css-151xaom-placeholder {
    visibility: hidden;
    position: relative;
    width: 100px;
}

.css-151xaom-placeholder:after {
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    content: "Search Fields";
    width: 100px;
}

.paginate_input {
    width: 9%;
    padding: 3px 10px;
}

.custom-page-link {
    position: relative;
    display: inline;
    cursor: pointer;
    padding: 0.5rem 0.75rem;
    line-height: 1.25;
    border: 1px solid;
    background-color: #ffffff !important;
    border-color: #dee2e6;
    color: #22b7c0;
}

.custom-page-link:hover {
    z-index: 2;
    color: #f95c2e;
    text-decoration: none;
    background-color: #e9ecef !important;
}

.paginate_total,
.data_list_previous {
    margin-right: 10px;
}

.data_list_first {
    border-radius: 5px 0 0 5px;
}

.data_list_last {
    border-radius: 0 5px 5px 0;
}

.react-paginate .pagination {
    margin: 0 5px 0 0;
}

.react-paginate .dt-button {
    padding: 6px 14px;
    margin-left: 5px;
}

.react-paginate {
    padding: 1.25rem;
}

/* Order Details page Table CSS */
.ReactTable .rt-tbody .rt-tr-group {
    border-bottom: none !important;
    background-color: #f8f9fa;
}

.ReactTable .rt-tbody .rt-td {
    border-left-width: 0;
    border-bottom-width: 0;
    border-right: 1px solid #dee2e6 !important;
    padding: 10px;
    /* font-size: 13px; */
}

.ReactTable .rt-thead.-filters {
    border-bottom: none !important;
}

.rt-th.rt-resizable-header {
    background-color: #2b2b2b;
    color: #fff;
    padding: 6px 6px !important;
    box-shadow: none !important;
    text-align: left !important;
}

.ReactTable .rt-thead.-headerGroups {
    background: #2b2b2b;
    color: #fff;
    /* box-shadow: none !important; */
    border-bottom: none !important
}

.ReactTable .rt-thead.-header {
    box-shadow: none !important;
}

.ReactTable .rt-thead.-filters .rt-th {
    border-right: 1px solid #dee2e6 !important;
    border-bottom-width: 0;
    border-left-width: 0;
}

.rt-draggable-container div.draggable-header.enable-drag {
    padding: 0px !important;
    border: none;
}

.draggable-header {
    width: 100%;
    justify-content: space-between;
}

/* .rt-tr.-padRow.-odd {
    background-color: #f8f9fa;
} */
.rt-th.rt-resizable-header.-sort-desc.-cursor-pointer:before {
    content: "\2191";
    right: 15px;
    position: absolute;
    font-size: 18px;
    top: -2px;
}

.rt-th.rt-resizable-header.-sort-asc.-cursor-pointer:after {
    content: "\2191";
    right: 15px;
    position: absolute;
    /* z-index: 9999999; */
    top: 3px;
    transform: rotate(180deg);
    font-size: 18px;
}

.rt-tr-group:hover {
    background-color: #f8f9fa;
}

.new-check .toggle input#filter_late_orders {
    display: none;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
    border: 1px solid #dee2e6;
}

.ReactTable .-sort-asc:before {
    content: "" !important;
}

.ReactTable .-cursor-pointer:before {
    content: "\2191";
    right: 15px;
    position: absolute;
    font-size: 18px;
    top: -2px;
}

.rt-th.rt-resizable-header .enable-drag {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
}

.ReactTable .rt-td,
.ReactTable .rt-th {
    border: 1px solid #dee2e6;
}

.ReactTable .rt-td {
    text-transform: capitalize;
}

.rt-tr-group:nth-child(2n) {
    background-color: #ffffff !important;
}

.ReactTable .rt-noData {
    top: 63% !important;
}

.ReactTable .-loading {
    background: transparent !important;
}

.ReactTable .rt-resizer {
    width: 20px !important;
    right: -10px !important;
    /* background-color: red; */
}

/* .ReactTable .rt-noData {
    align-items: center;
    justify-content: center;
    width: 100% !important;
    height: 42% !important;
    top: 78% !important;
    display: flex !important;
    background: #cccccc !important;
    color: #4f5d73 !important;
 }
 .ReactTable.test .rt-noData {
    height: 32% !important;
    top: 71% !important;
 } */
/* .ReactTable .rt-noData {
    background: transparent !important;
    color: #4f5d73 !important;
} */
/* .animate {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    }

.five {
    -webkit-animation-delay: 0.2s;
    -moz-animation-delay: 0.2s;
    animation-delay: 0.2s;
}
@keyframes fadeIn {
    from {
    opacity: 0;
    }
    to {
    opacity: 1;
    }
}
.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
} */
.modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    -o-transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    /* -webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    transform: translate(0, -25%); */
}

.fade {
    transition: opacity .1s linear;
}

.animated {
    -webkit-animation-duration: 1s !important;
    animation-duration: 1s !important;
    -webkit-animation-fill-mode: both !important;
    animation-fill-mode: both !important;
}

.flipInY {
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipInY !important;
    animation-name: flipInY !important;
}

.c-header-nav .flipInY {
    top: 100% !important;
    left: -60px !important;
}

@keyframes flipInY {
    0% {
        transform: perspective(400px) rotateY(90deg);
        opacity: 0;
    }

    40% {
        transform: perspective(400px) rotateY(-10deg);
    }

    70% {
        transform: perspective(400px) rotateY(10deg);
    }

    100% {
        transform: perspective(400px) rotateY(0deg);
        opacity: 1;
    }
}

.export-btn .css-0 {
    margin-left: 5px;
}

.btn-primary.disabled {
    background-color: #22b7c0 !important;
    border-color: #22b7c0 !important;
}

/* Ticket Details  */
/* .td1-left {
    min-width: 145px;
}

.table-css td {
    padding: 11px 6px !important;
}

.table-a-break td a {
    max-width: 100%;
    word-break: break-all;
}

.card.marginbnone {
    margin-bottom: 5px;
    border-radius: 2px;
    border: solid 1px #d5d5d5;
}
.list-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}
.bg-teal {
    background-color: #22b7c0 !important;
    padding: 7px 10px;
} */
.ticket_details .card-body {
    padding: 0;
    border: none !important;
}

.ticket_details .card-body table .card.marginbnone {
    box-shadow: 0px 0px 10px -4px #989898;
    ;
}

.upload-file-status-button {
    background-color: #f8f9fa;
    border-color: #dee2e6;
    margin-left: 8px;
}

.upload-file-status-button:hover {
    background-color: #e2e6ea !important;
    border-color: #dae0e5 !important;
}

.upload-file-status-button:focus {
    background-color: #f8f9fa !important;
    border-color: #dee2e6 !important;
}

.offline {
    background-color: #c3b9b9;
}

.online {
    background-color: green;
}

.away {
    background-color: #ffcb2d;
}

.login-status {
    width: 10px !important;
    height: 10px !important;
    display: inline-block;
    position: absolute;
    /* margin-right: 10px; */
    border-radius: 5px;
    margin-left: -22px !important;
}

.c-sidebar-nav li>a {
    position: relative;
}

.input-group-text {
    height: 35px !important;
    align-items: baseline;
}

.add-product-select {
    position: absolute !important;
    width: 70% !important;
}

/* ---------------------Ticket details page CSS start---------------- */

.ticket-message-box {
    box-shadow: 0px 0px 10px -4px #989898;
    border-radius: 2px;
    border: solid 1px #d5d5d5;
    margin-bottom: 0.5rem !important;
}

.comment-box .editorStyles_editor__D1MjC {
    border-radius: 0.25rem;
}

/* ---------------------Ticket details page CSS END---------------- */
/* Payment table css  start*/
.payment-table-switch .c-switch-input {
    position: fixed !important;
}

/* -----------Return Details Page------------ */
.return-comment-box .editorStyles_editor__44Up6 {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    height: 60px;
    padding: 10px;
    border-radius: 2px;
    margin-bottom: 1em;
    box-shadow: inset 0px 1px 8px -3px #ababab;
    /* background: #fefefe; */
}

/* Table font size */
.ReactTable .rt-thead .rt-resizable-header-content {
    font-size: 12px !important;
    font-weight: 500;
    border: none !important;
}

h2 {
    font-size: 30px;
    font-weight: 300;
}

h4 {
    font-size: 18px !important;
}

.card-title {
    font-size: 18px !important;
}

.ticket-card {
    box-shadow: 0 0 10px -4px #989898;
    border: none;
    border-radius: 0 !important;
}

.progress-bars-groups {
    border: none;
    border-radius: 0 !important;
    box-shadow: 0 0 10px -4px #989898;
}

.card {
    border: none;
    border-radius: 0 !important;
    box-shadow: 0px 0px 10px -4px #989898;
}

.ReactTable .rt-thead .rt-td,
.ReactTable .rt-thead .rt-th {
    font-size: 12px;
    font-weight: 500;
}

.table {
    color: #212529;
}

.c-app {
    color: #212529;
}

.ReactTable .rt-thead .rt-resizable-header-content {
    text-transform: uppercase;
}

.modal-title {
    font-weight: 300 !important;
}

.word-break {
    word-break: break-word;
}

b,
strong {
    font-weight: 500 !important;
}

.return-id-section table tr>td {
    padding: 11px 6px !important;
}

.return-id-section table tr>td:first-child {
    font-size: 13px;
    font-weight: 400;
    width: 225px;
}

.table-strip tbody tr:nth-of-type(odd) {
    background-color: #f8f9fa;
}

.editorStyles_editor__3vGam.col {
    box-shadow: none !important;
    border-radius: 4px;
    background-color: #fff;
}

.table-strip tbody tr td {
    padding: 6px !important;
}

.return-id-section td {
    font-size: 13px;
}

.return-id-section svg.c-icon.c-icon-lg {
    width: 1rem !important;
    height: 1rem !important;
}

/* dispute canned  */
.label {
    padding: 3px 10px;
    line-height: 13px;
    color: #fff;
    font-weight: 400;
    border-radius: 0.25rem;
    font-size: 75%;
}

.label-danger {
    background-color: #e46a76;
}

.label {
    display: inline-block;
}

.css-1okebmr-indicatorSeparator {
    width: 0 !important;
}

/* UI Issue CSS------------------ */

.container-fluid {
    padding: 0px 25px !important;
}

.dashboard .col-md-8,
.dashboard .col-md-4,
.dashboard .col-lg-6,
.dashboard .col-lg-8,
.dashboard .col-lg-4 {
    padding-right: 10px !important;
    padding-left: 10px !important;
}

.dashboard .row {
    margin-left: -10px !important;
    margin-right: -10px !important;
}

.carousel .slide .avea-img-box {
    max-height: 500px;
}

.carousel .slide .avea-img-box .avea-img {
    width: auto;
    max-height: 500px;
}

.carousel .thumb .avea-img {
    width: 100%;
}

.carousel .thumbs-wrapper .thumbs {
    margin-bottom: 0;
}

.tooltip {
    width: 250px;
    white-space: normal;
}

.gray-overlay {
    width: 100%;
    position: absolute;
    left: 0;
    opacity: 0.7;
    background: #2a2d30;
    z-index: 1;
}

.sm-overlay {
    height: 55%;
    top: 45%;
}

.full-overlay {
    height: 100%;
    top: 0;
}

.aopPopup .modal-content,
.aopPopup p,
.aopPopup strong {
    background-color: #000;
    color: #fff;
}

.aopPopup .close {
    color: #fff;
    text-shadow: none;
}

.bold {
    font-weight: bold;
}

.c-sidebar-nav-dropdown-items>.second-dropdown a.c-sidebar-nav-dropdown-toggle {
    padding-left: 45px;
}

.second-dropdown-item>a.c-sidebar-nav-link {
    padding-left: 56px !important;
}

@media (max-width: 767px) {
    .container-fluid {
        padding: 0px 15px 0px 15px !important;
    }
}

.bg-black {
    background-color: black !important;
}

.contact .modal.fade .modal-dialog {
    max-width: 700px !important;
}

.draftJsMentionPlugin__mentionSuggestions__2DWjA {
    max-height: 190px !important;
    overflow: auto;
}

.__react_component_tooltip {
    font-size: 14px !important;
    padding: 3px 8px !important;
}

/* .profit-loss-tooltip::after {
    left: 100px !important;
    border-left: 8px solid transparent !important;
    border-right: 8px solid transparent !important;
    bottom: -6px !important;
    margin-left: -8px !important;
    border-top-color: #222 !important;
    border-top-style: solid !important;
    border-top-width: 6px !important;
    top: 27px !important;
    margin-top: 0px !important;
}

.profit-loss-tooltip {
    left: 250px !important;
    margin-left: 0px !important;
    opacity: 1 !important;
} */

.customtooltip {
    position: absolute;
    display: inline-block;
}

.customtooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 5px 5px;
    position: absolute;
    z-index: 1;
    left: 55px;
    margin-left: -60px;
    top: -47px;
}

.customtooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 100px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.customtooltip:hover .tooltiptext {
    visibility: visible;
}

.error-box {
    height: 100%;
    width: 100%;
}

.error-body h1 {
    /* font-size: 210px; */
    font-size: 55px;
    font-weight: 900;
    /* text-shadow: 4px 4px 0 #fff, 6px 6px 0 #343a40; */
    text-shadow: 3px 3px 0 #fff, 5px 5px 0 #343a40;
    line-height: 55px;
}

.error-body .page-not-found {
    margin-bottom: 0.5rem;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    line-height: 1.2;
    color: inherit;
}

.rt-tbody .rt-tr-group:first-child .rt-tr .rt-td .customtooltip .tooltiptext::after {
    top: -10px;
    border-bottom-color: black;
    border-top-color: transparent;
}

.rt-tbody .rt-tr-group:first-child .rt-tr .rt-td .customtooltip .tooltiptext {
    top: 24px;
}

/* Dashboard Header Notification CSS */
.header-dropdown {
    width: 350px !important;
}

.header-dropdown .notification-box {
    padding: 10px 0px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 300px;
}

.header-dropdown:before {
    content: "";
    position: absolute;
    top: -20px !important;
    right: 10px;
    border: 10px solid #343A40;
    border-color: transparent transparent #343A40 transparent;
    top: 37px;
}

.notification_alert_icon {
    margin-right: 12px;
    margin-left: 5px;
}

.rounded-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.notification_div_row {
    padding: 10px 0px;
    position: relative;
}

.amazon-progressbar-color .progress-bar {
    background-color: #f8a17b !important;
}

.ReactTable .rt-tr {
    position: relative;
}

.setting-app header.card-header {
    font-size: 18px,
}

.tableFixHead {
    overflow-y: auto;
}

.tableFixHead thead th {
    position: sticky;
    top: 0;
    z-index: 9;
}

#data_list_1 td,
#data_list_1 th {
    text-align: center;
}

.add_new_package {
    margin-bottom: 5px;
}

.remaining-sku {
    color: #2b2b2b;
    border-radius: 2px;
    padding: 5px;
    margin-top: 10px;
}

.ui-sortable-handle {
    background: transparent !important;
}

.t_sortable tr,
.ui-sortable-helper {
    cursor: move;
}

.t_sortable tr:first-child {
    cursor: default;
}

.separate_order_btn {
    background-color: black;
    color: white;
}

.ui-sortable-handle {
    background: transparent !important;
}

.tables_ui {
    display: table;
    border: 3px solid #fff;
    border-spacing: 0;
    min-height: 20px;
}

.tables_ui ul li {
    min-width: 200px;
}

.dragging li.ui-state-hover {
    min-width: 240px;
}

.dragging .ui-state-hover a {
    color: green !important;
    font-weight: bold;
}

/* .tables_ui th,td {
  text-align: right;
  padding: 2px 4px;
  border: 1px solid;
} */
.c-sidebar-nav-item .c-sidebar-nav-link {
    white-space: normal !important;
}

.unread_notification {
    background-color: #ffe0e3;
}

.odd a:hover,
a:focus,
a:active {
    color: #22b7c0;
    text-decoration: none;
}

.chat-box .popup-box {
    display: none;
    position: fixed;
    bottom: 0px;
    right: 10px;
    height: 400px;
    background-color: rgb(237, 239, 244);
    width: 350px;
    border: 1px solid rgba(29, 49, 91, .3);
    z-index: 9999;
}

.chat-box .popup-box .popup-head {
    background-color: #22b7c0;
    padding: 5px;
    color: white;
    font-weight: bold;
    font-size: 14px;
    clear: both;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.chat-box .popup-messages .msg-conversation {
    width: 100%;
    position: relative;
    top: 0px;
    height: 100%;
}

.chat-box .popup-messages .chat-action {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
}

.search-filter-main {
    /* position: absolute; */
    background: #FFF;
    filter: drop-shadow(0 0 6px #666);
    display: block;
    padding: 20px 20px 20px 5px;
}

.chat-form .input-cont {
    margin-right: 70px;
}

.chat-box .chat-form .btn-cont .arrow {
    position: absolute;
    top: 17px;
    right: 43px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #22b7c0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.chat-form {
    margin-top: 5px;
    padding: 5px;
    background-color: #01c0c833;
    overflow: hidden;
    clear: both;
    width: 100%;
}

.chat-form .input-cont .form-control {
    width: 99% !important;
    margin-bottom: 0px;
    border-radius: 0px;
    min-height: 38px;
    resize: none;
}

.chat-form .btn-cont {
    margin-top: -48px;
    position: relative;
    float: right;
    width: 44px;
}

.chat-form .btn-cont .btn {
    margin-top: 5px;
    padding: 0.405rem 0.75rem;
    background: #22b7c0;
}

.chat-form .btn-cont .btn svg {
    color: #fff;
}

.suppliers-details-span {
    display: inline-block;
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 7.5px 10px;
    color: #4F5467;
    background-color: #dcdcdc;
    background-clip: padding-box;
    border: 1px solid #dcdcdc;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    min-height: 38px;
}

.suppliers-details .large_checkbox {
    width: 50px;
    height: 25px;
}

.suppliers-details .image_upload_preview {
    width: 100%;
    min-height: 166px;
    overflow: hidden;
    position: relative;
    display: inline-block;
    background: transparent;
    text-align: center;
    display: flex;
    align-items: center;
}

.btn_align button.btn {
    padding: 5px 10px;
    font-size: 12px;
}

.suppliers-details h4 .box-title {
    background: #2a2a2a;
    margin: 0 -20px;
    padding: 12px 0;
    color: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

@media only screen and (max-width: 460px) {
    ul.graph-data.text-center li {
        width: 100%;
        margin-bottom: 12px;
    }
}

.done-cl {
    color: #00c292;
}

.inprogress-cl {
    color: #22b7c0;
}

.pending-cl {
    color: #6c757d;
}

.shipstation-order-table-badge {
    height: 30px;
    width: 35px;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    color: #ffffff;
    cursor: pointer;
}

.shipstation-order-table-badge:last-child {
    margin-right: 0px;
}

.table-a-break .table td {
    font-size: 13px !important;
}

.customer_name {
    /* position: absolute;
    top: 50%;
    transform: translateY(-50%); */

    position: static;
    top: unset;
    transform: unset;
}

table#data_pick_list p {
    font-size: 15px;
}

.slide-panel-div {
    position: absolute;
    background-color: #fff;
    padding: 20px 10px 10px 10px;
    z-index: 3;
}

.close-div-printer {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
}

.choose-printer-popup {
    background-color: #f5f5f5;
    border: 1px dashed black;
}

@media print {
    table {
        page-break-inside: auto
    }

    tr {
        page-break-inside: avoid;
        page-break-after: auto
    }

    thead {
        display: table-header-group
    }

    tfoot {
        display: table-footer-group
    }

    @page {
        size: all;
        margin-top: 30px;
        margin-bottom: 14mm;
        page-break-after: always;
    }

    body * {
        visibility: hidden;
    }

    #picklist_print_table,
    #picklist_print_table * {
        visibility: visible;
    }

    #picklist_print_table th {
        font-size: 16px !important;
        color: #000 !important;
    }

    #picklist_print_table {
        margin-top: -30px;
    }
}

iframe {
    height: 100vh;
    width: 100vw;
    position: fixed;
}

#hide_void_label_div {
    padding-right: 10px;
    padding-top: 5px;
}

.new_btn_bg {
    font-size: 13px;
}

.close {
    float: right;
    font-size: 1.3125rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.void_label_btn {
    color: white;
    padding: .25rem .5rem;
    font-size: 11px;
}

.column-sym-new {
    width: 115px;
    max-width: 115px;
}

.ticket-style tr td {
    word-wrap: unset !important;
    min-width: 140px;
    width: 140px;
    word-break: break-all;
}

.toggle.btn-sm {
    min-height: 28px;
}

input {
    height: auto !important;
}

a.hide {
    display: none;
}

.myDIV:hover .hide {
    display: block;
}

.myDIV {
    display: inline-flex;
    align-items: center;
}

a.sku-occurance:hover {
    color: #fff;
    background-color: #bf0e20;
    text-decoration: none;
}

.label-warning {
    background-color: #fec107;
}

.label-success {
    background-color: #00c292;
}

.tax_percent {
    height: 40px !important;
    align-items: baseline;
}

.modal.fade {
    background-color: #00000070;
}

.c-sidebar-nav-dropdown-items .c-sidebar-nav-link {
    padding-left: 45px !important;
}

/* Ring Loader CSS */
/* .existing-product-loader{
    width: 2em  !important;
} */

.existing-products>.svg-inline--fa.fa-w-16 {
    width: 2em !important;
}

.existing-products>.svg-inline--fa {
    height: 1.3em !important;
}

.display-none {
    display: none;
}

.disabled .page-link {
    display: none;
}

.export-download>.svg-inline--fa {
    height: 1.3em !important;
}

.export-download>.svg-inline--fa.fa-w-16 {
    width: 2em !important;
}

.return-table .rt-tr .rt-th:first-child {
    border-bottom: transparent;
    text-align: center !important;
}

.return-table .rt-thead .rt-tr .rt-th:first-child {
    border-top: transparent;
    text-align: center !important;
}

.label_shipping_select {
    font-size: 12px !important;
    height: 24px !important;
}

.customtooltip2 {
    position: absolute;
    display: inline-block;
}

.customtooltip2 .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 5px 5px;
    position: absolute;
    z-index: 1;
    left: -76px;
    margin-left: 0;
    top: 29px;
}

.customtooltip2 .tooltiptext::after {
    content: "";
    position: absolute;
    top: -10px;
    left: 48px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-bottom-color: black;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
}

.row_tool_tip:hover .tooltiptext {
    visibility: visible;
}

.rt-tbody .rt-tr-group:first-child .rt-tr .rt-td .customtooltip2 .tooltiptext::after {
    top: 12px;
    border-right-color: black;
    border-top-color: transparent;
}

.rt-tbody .rt-tr-group:first-child .rt-tr .rt-td .customtooltip2 .tooltiptext {
    top: -6px;
}

.walmart-progress-bar .progress-bar {
    background-color: #ffff66 !important;
}

.facebook-progress-bar .progress-bar {
    background-color: #4267B2 !important;
}

.kroger-progress-bar .progress-bar {
    background-color: #ff5422 !important;
}

.walmart-canada-progress-bar .progress-bar {
    background-color: #ff9800 !important;
}

.oriental-trading-company-progress-bar .progress-bar {
    background-color: #ff99aa !important;
}

.groupon-progress-bar .progress-bar {
    background-color: #89eba6 !important;
}

.pea-pod-progress-bar .progress-bar {
    background-color: #74992e !important;
}

.snapcommerce-progress-bar .progress-bar {
    background-color: #5C4033 !important;
}

.bed-bath-beyond-progress-bar .progress-bar {
    background-color: #1a4e8a !important;
}

.bg-td-product input.product-input:focus {
    color: #212529;
}

.add-product-select .css-109onse-indicatorSeparator {
    background-color: transparent;
}

.react-tags {
    position: relative;
    padding: 6px 0 0 6px;
    border: 1px solid #D1D1D1;
    border-radius: 1px;
    font-size: 1em;
    line-height: 1.2;
    cursor: text;
    box-shadow: inset 0px 1px 8px -3px #ababab;
    min-height: 130px;
}

.react-tags.is-focused {
    border-color: #B1B1B1;
}

.react-tags__selected {
    display: inline;
}

.react-tags__selected-tag {
    display: inline-block;
    box-sizing: border-box;
    margin: 0 6px 6px 0;
    padding: 6px 8px;
    border: 1px solid #D1D1D1;
    border-radius: 20px;
    background: #22b7c0;
    font-size: inherit;
    line-height: inherit;
    color: #fff;
}

.react-tags__selected-tags:after {
    content: '\2715';
    color: #fff;
    margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
    border-color: #B1B1B1;
}

.react-tags__search {
    display: inline-block;
    padding: 7px 2px;
    margin-bottom: 6px;
    max-width: 100%;
}

@media screen and (min-width: 30em) {
    .react-tags__search {
        position: relative;
    }
}

.react-tags__search-input {
    max-width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    font-size: inherit;
    line-height: inherit;
    background: none !important;
}

.react-tags__search-input::-ms-clear {
    display: none;
}

.react-tags__suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 1;
}

@media screen and (min-width: 30em) {
    .react-tags__suggestions {
        width: 240px;
    }
}

.react-tags__suggestions {
    overflow-y: auto;
    overflow-x: hidden;
    height: 300px;
}

.react-tags__suggestions ul {
    margin: 4px -1px;
    padding: 0;
    list-style: none;
    background: #22b7c0;
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
    border-bottom: 0px solid #ddd;
    padding: 10px 15px;
}

.react-tags__suggestions li mark {
    background: none;
    font-weight: 600;
}

.react-tags__suggestions li:hover {
    cursor: pointer;
    background: #e6f3ff;
}

.react-tags__suggestions li.is-active {
    background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
    opacity: 0.5;
    cursor: auto;
}

.btn.disabled,
.btn:disabled {
    cursor: default;
}

.sort-icon {
    float: right !important;
    cursor: pointer !important;
}

.main-chat-icon.highlight,
.chat-user .ur_count.highlight {
    color: #fff;
    background-color: red;
    margin-left: 7px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: inline-block;
    text-align: center;
}

.column-sym2 {
    width: 200px !important;
    max-width: 200px !important;
}

.red_color {
    color: #ee2c2c !important;
    font-weight: 600;
}

.walmart_razzle_cron_switch .c-switch-label {
    width: 56px !important;
}

.walmart_razzle_cron_switch .c-switch-lg {
    height: 33px !important;
}

.walmart_razzle_cron_switch .c-switch-square .c-switch-slider {
    border-radius: 5px !important;
}

.chat-user-display {
    display: none;
}

.confirmation-modal {
    z-index: 1000000;
}

.upload_product_modal .modal-dialog {
    max-width: 600px !important;
}

.auto-select-input {
    border: 1px solid #999;
    padding: 0.5rem;
    min-width: 200px;
}

.no-suggestions {
    color: #999;
    padding: 0.5rem;
}

.suggestions {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    border-radius: 0 0 5px 5px;
    /* width: calc(300px + 1rem); */
}

.suggestions li {
    padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
    background-color: #ffffff;
    /* color: #fae042; */
    cursor: pointer;
    font-weight: 700;
}

.suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
}

@media (min-width: 2100px) {
    .customSet {
        max-width: 46.33333% !important;
    }
}

.scanner-select-button-color {
    background-color: #000000;
    color: #ffffff;
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1300px;
    }
}

.partial-order-popup-max-h {
    max-height: 550px;
    overflow: auto;
}

.text-center.rt-resizable-header {
    text-align: center !important;
}

/* .scrollable::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar,
.rt-table::-webkit-scrollbar, */
body::-webkit-scrollbar {
    width: 1em;
    height: 1em;
}



body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}
